import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import { getQueryParamName, getDependOptions } from '../../../util/search';
import { FormattedMessage } from '../../../util/reactIntl';

import IconPlus from '../IconPlus/IconPlus';

import css from './SelectSingleFilterPlain.module.css';

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      filterOptions: [],
      extendedOptions: [],
    };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);

    this.handleOnSeeMore = this.handleOnSeeMore.bind(this);
    this.handleOnShowAll = this.handleOnShowAll.bind(this);
  }

  componentDidMount() {
    this.updateOptions(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options || prevProps.dependOn !== this.props.dependOn) {
      this.updateOptions(this.props);
    }
  }

  updateOptions(props) {
    const filterOptions = props.dependOn ? getDependOptions(props.options, props.urlQueryParams, props.dependOn) : props.options;
    this.setState({
      filterOptions,
      extendedOptions: filterOptions.slice(0, 5),
    });
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }


  handleOnSeeMore = () => {
    // add 5 more options to the filter
    this.setState(prevState => ({
      extendedOptions: this.state.filterOptions.slice(0, prevState.extendedOptions.length + 5),
    }));
  };

  handleOnShowAll = () => {
    this.setState({ extendedOptions: this.state.filterOptions });
  };

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      urlQueryParams,
      queryParamNames,
      initialValues,
      dependOn,
      twoColumns,
      useBorder,
      useHighlight,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.labelSelected : css.label;


    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    const isExtendedAllOptions = this.state.extendedOptions.length >= this.state.filterOptions.length;

    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>{label}</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={!!initialValue} />
              </span>
            </span>
          </button>
        </div>
        <div className={optionsContainerClass}>
          {this.state.extendedOptions.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                })
              : null;
            return (
              <button
                key={option.key}
                className={optionClass}
                onClick={() => this.selectOption(option.key)}
              >
                {useBorder ? <span className={optionBorderClass} /> : null}
                {option.label}
              </button>
            );
          })}
          {!isExtendedAllOptions && (
            <div className={css.optionals}>
              <button className={css.clearButton} onClick={this.handleOnSeeMore}>
                <FormattedMessage id={'FilterPlain.seeMore'} />
              </button>
              <button className={css.clearButton} onClick={this.handleOnShowAll}>
                <FormattedMessage id={'FilterPlain.showAll'} />
              </button>
            </div>
          )}
          <button className={css.clearButton} onClick={e => this.selectOption(null, e)}>
            <FormattedMessage id={'SelectSingleFilter.plainClear'} />
          </button>
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  deopendOn: false,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  dependOn: string,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default SelectSingleFilterPlain;
